import flag from './flag.png';
import style from './Footer.module.scss';

export default function Footer() {
  return (
    <footer className={style.base}>
      <div className={style.wrapper}>
        <div className={style.textContainer}>
          <p className={style.copyright}>© Atomus Corporation</p>
          <a className={style.privacy} href="/documents/privacy-policy.pdf">Privacy Policy</a>
        </div>
        <div className={style.location}>
          <img src={flag} alt="United States flag" />
          <p className={style.text}>575 Market St, 415, San Francisco, California 94105, United States</p>
        </div>
      </div>
    </footer>
  );
}
