import { useEffect } from 'react';
import Footer from '../components/Footer/Footer';
import OldNavbar from '../components/Navbar/Navbar';
import '../components/utilities/helpscout';
import styles from '../styles/PageContentStyles.module.scss';

export default function Referral() {
  useEffect(() => {
    return () => {

    };
  }, []);

  return (
    <>
      <OldNavbar />
      <section className={styles.base}>
        <section className={styles.centeredWrapper}>
          <iframe className={styles.airtableForm} src="https://airtable.com/embed/shriIwF5moIngHTDl?backgroundColor=yellow"
            frameBorder="0"
            width="100%"
            height="3318"
          ></iframe>
        </section>
        <div className={styles.airtableForm} id="airtable-embed"></div>
      </section>
      <Footer />
    </>
  );
}
