import {
  Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay
} from "@chakra-ui/react";

interface IConfirmModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
}

export default function KickOffConfirmModal({
  isModalOpen,
  setIsModalOpen,
  onSubmit,
}: IConfirmModalProps) {
  const handleConfirmation = () => {
    setIsModalOpen(false);
    onSubmit();
  };
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader>Submit Kick-off Form</ModalHeader>
        <ModalBody>
          Clicking "Confirm" marks the official beginning of the onboarding process. Proceed?
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleConfirmation}>
            Confirm
          </Button>
          <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
