import { Flex, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCompanyInfo } from '../api/api.client';
import { FormCompanyInfo } from '../api/api.types';
import { demoSubContractorInfo } from '../constants/demo.constants';
import SCTemplate from './sc-template';
import DemoSCTemplate from './sc-template-demo';

export default function CatchAllRoute() {
  const [companyData, setCompanyData] = useState<FormCompanyInfo | null>();
  const [shortform, setShortform] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const loadCompanyInfo = async () => {
      setShortform(lastSegment);
      if (shortform) {
        setCompanyData(await getCompanyInfo(shortform));
        setIsLoading(false);
      }
    };
    loadCompanyInfo();
  }, [shortform, setShortform]);

  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const lastSegment = currentPath.substring(currentPath.lastIndexOf('/') + 1);

  if (isLoading) {
    return (
      <Flex justifyContent='center' alignItems='center' height='200px'>
        <Spinner size='lg' />
      </Flex>
    );
  }

  if (companyData) {
    return <SCTemplate companyInfo={companyData} />;
  }

  for (let data of Object.values(demoSubContractorInfo)) {
    if (data.url === lastSegment) {
      return <DemoSCTemplate companyName={data.name} companyLogo={data.logo} />;
    }
  }
  if (!isLoading && !companyData) {
    navigate('/');
  }
  return null;
};
