export interface TrainingSubmission {
  name: string;
  email: string;
  cuiCert: File;
  insiderThreatCert: File;
}

export interface VisitorLogsSubmission {
  name: string;
  email: string;
  logsFile: File;
  quarter: string;
}

export interface VisitorFormSubmission {
  name: string;
  phone: string;
  email: string;
  isUsCitizen: boolean | null;
  location: string | null;
  timeOfVisit: string | null;
  purposeOfVisit: string;
  employeeName: string;
  employeeEmail: string;
}

export enum DeviceOS {
  Mac = "MacOS",
  Windows = "Windows",
  Android = "Android",
  iOS = "iOS",
}

export interface AegisUser {
  companyId: string;
  userType: string;
  firstName: string;
  lastName: string;
  username: string | undefined;
  email: string;
  msftTempPass: string;
  phone: string;
  numberOfDevices: number;
  devices: DeviceOS[];
}

export enum O365CloudType {
  gccHigh = "gccHigh",
  gcc = "gcc",
}

export interface KickOffFormFields {
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  adminPhone: string;
  adminUsername: string;
  companyName: string;
  companyWebsite: string;
  companyAddress: string;
  numUsers: string;
  additionalDevices: string;
  countOfServers: string;
  cloudInstance: O365CloudType;
  needLicenses?: boolean;
  call1StartTime?: string;
  onboardingDeadline: string;
  migration?: boolean;
  call2StartTime?: string;
  migrationRemarks?: string;
  customDomain?: string;
  scoreRequired?: boolean;
  call3StartTime?: string;
  shortForm?: string;
}

export interface SubcontractorFormFields {
  companyId: string;
  scCompanyName: string;
  contactName: string;
  contactEmail: string;
  infoSystemsCompliant: boolean;
  allControlsImplemented: boolean;
  basic31Controls?: boolean;
  atLeast100Controls?: boolean;
  poam: boolean;
  poamClosureDate: string;
  pendingControls: string;
  selfSprs: boolean;
  sprsDate: string;
  nistDibcac: string;
  dibcacDate: string;
  cotsOnly: boolean;
  securityPlanNameAndVersion: string;
  cageCode: string;
}

export interface FormCompanyInfo {
  id: string;
  name: string;
  links: {
    logo: string;
  }[];
}

export type SuccessAegisResponse<T> = {
  success: true;
  code: number;
  data: T;
};

export type ErrorAegisResponse<T> = {
  success: false;
  code: number;
  error: string;
  data: T;
};

export type AegisResponse<SuccessT, ErrorT = null> =
  | SuccessAegisResponse<SuccessT>
  | ErrorAegisResponse<ErrorT>;
