import { Button, FormControl, FormHelperText, FormLabel, Input, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendTrainingSubmission } from '../api/api.client';
import { TrainingSubmission } from '../api/api.types';
import FormContainer from '../components/FormContainer/FormContainer';
import Page from '../components/Page/Page';
import { checkFile } from '../services/file.service';
import { toastFormError } from '../services/formerror.service';

export default function TrainingCertificateForm() {
  const titleElement = document.getElementById('tab-title');
  if (titleElement) {
    titleElement.textContent = `Atomus | Training Submission`;
  }
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cuiFile, setCuiFile] = useState<File | null>(null);
  const [threatFile, setThreatFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const setFile = async (event: React.ChangeEvent<HTMLInputElement>, setStateFn: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (event.target.files) {
      setStateFn(event.target.files[0] || null);
    } else {
      // should never happen
      console.error("files is null after upload");
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (cuiFile && threatFile) {
        checkFile(cuiFile);
        checkFile(threatFile);
        const submission: TrainingSubmission = {
          name,
          email,
          cuiCert: cuiFile,
          insiderThreatCert: threatFile
        };
        await sendTrainingSubmission(submission);
        navigate("/submit-success");
      }
    } catch (error) {
      toastFormError(toast, error);
    }
    setIsLoading(false);
  };

  return (
    <Page>
      <Text fontSize="2xl" fontWeight="bold">Training Certificate Submission</Text>
      <FormContainer>
        <form>
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Name</FormLabel>
            <Input required value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl isRequired marginY={8}>
            <FormLabel>Email</FormLabel>
            <FormHelperText as="i">Note: please only submit certificates for the user with this email address.</FormHelperText>
            <Input marginTop={2} required type="email" onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl isRequired marginY={8}>
            <FormLabel>CUI Training Certificate</FormLabel>
            <input type="file" onChange={(e) => setFile(e, setCuiFile)} />
          </FormControl>
          <FormControl isRequired marginY={8}>
            <FormLabel>Insider Threat Training Certificate </FormLabel>
            <input type="file" onChange={(e) => setFile(e, setThreatFile)} />
          </FormControl>
        </form>
        <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading} isDisabled={![name, email, cuiFile, threatFile].every(val => Boolean(val))}>Submit</Button>
      </FormContainer>
    </Page >
  );
}
