import { DemoSubcontractor } from '../types';

export const demoSubContractorInfo: Record<string, DemoSubcontractor> = {
  arcs: {
    url: '590X3SLdN5hZ',
    name: 'Arcs Aviation Supplier',
    logo: 'https://www.arcsaviation.com/wp-content/uploads/ARCS-LOGO-100.png'
  },
  cenith: {
    url: 'Q2GTS0Sub0S7',
    name: 'Cenith Innovations',
    logo: 'https://cenithinnovations.com/wp-content/uploads/2020/11/CEN-logo-primary-dark-dropshadow-web.png'
  },
  ibc: {
    url: '903NlT3X2zz9',
    name: 'IBC Materials & Technologies Supplier',
    logo: 'https://www.ibcmaterials.com/wp-content/uploads/IBC_Materials_Red_Dot_Logo-Transparent.png'
  },
  ncd: {
    url: 'jLW2vwi5975U',
    name: 'NCD Technologies Supplier',
    logo: 'https://images.squarespace-cdn.com/content/v1/572b989f60b5e93586adbd73/1462556153119-YTNHDRKTIT5WC0JUCOGQ/ncd_logo_lg.png?format=1500w'
  },
  rippleTwo: {
    url: 'fHHrA92oG0Dq',
    name: 'Ripple Two Supplier',
    logo: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcT7FAT1Osw4PqUK_6Yzuys1L8ClBPNTxneGkHb8slj1Cm_YBl4o'
  }
};
