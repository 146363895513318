import { useEffect } from 'react';
import Footer from '../components/Footer/Footer';
import OldNavbar from '../components/Navbar/Navbar';
import '../components/utilities/helpscout';
import styles from '../styles/PageContentStyles.module.scss';

export default function Home() {
  useEffect(() => {
    window.location.href = 'https://atomuscyber.us';
  }, []);

  return (
    <>
      <OldNavbar />
      <section className={styles.base}>
        <section className={styles.centeredWrapper} />
      </section>
      <Footer />
    </>
  );
}
