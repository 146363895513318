import React from 'react';
import { createRoot } from "react-dom/client"
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
// import './styles/global.scss';

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <ChakraProvider>
    <Router>
      <App />
    </Router>
</ChakraProvider>
  </React.StrictMode>
);
