import { UseToastOptions } from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { ErrorAegisResponse } from '../api/api.types';

export function getApiError(error: any): string {
  if (axios.isAxiosError(error)) {
    return error.message;
  }
  const aegisError = (error as AxiosError<ErrorAegisResponse<any>>);
  if (isErrorAegisResponse(aegisError)) {
    return aegisError?.error;
  }
  if (!error) {
    return "Network error.";
  }
  return error;
}

export function isErrorAegisResponse(
  data: any
): data is ErrorAegisResponse<any> {
  return data && data.isAegisResponse && data.success === false;
}

export function toastFormError(toast: (options?: UseToastOptions) => void, error: any) {
  const errorMsg = getApiError(error);
  toast({
    title: "Error",
    position: 'top-right',
    description: `${errorMsg}`,
    status: "warning",
    duration: 10000,
    isClosable: true
  });
}
