import { Button, FormControl, FormLabel, Input, Select, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendVisitorLogsSubmission } from '../api/api.client';
import { VisitorLogsSubmission } from '../api/api.types';
import FormContainer from '../components/FormContainer/FormContainer';
import Page from '../components/Page/Page';
import { checkFile } from '../services/file.service';
import { toastFormError } from '../services/formerror.service';

export default function VisitorLogs() {
  const titleElement = document.getElementById('tab-title');
  if (titleElement) {
    titleElement.textContent = `Atomus | Visitor Logs`;
  }
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [quarter, setQuarter] = useState("");
  const [logsFile, setLogsFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const setFile = async (event: React.ChangeEvent<HTMLInputElement>, setStateFn: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (event.target.files) {
      setStateFn(event.target.files[0] || null);
    } else {
      // should never happen
      console.error("files is null after upload");
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (logsFile) {
        checkFile(logsFile);
        const submission: VisitorLogsSubmission = {
          name,
          email,
          logsFile: logsFile,
          quarter,
        };
        await sendVisitorLogsSubmission(submission);
        navigate("/submit-success");
      }
    } catch (error) {
      toastFormError(toast, error);
    }
    setIsLoading(false);
  };

  return (
    <Page>
      <Text fontSize="2xl" fontWeight="bold" marginBottom={2}>
        Visitor Logs Upload Form
      </Text>
      <Text marginBottom={4}>Please upload your visitor logs to this form as a .zip file</Text>
      <FormContainer>
        <form>
          <FormControl isRequired marginBottom={4}>
            <FormLabel>Name</FormLabel>
            <Input required value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl isRequired marginBottom={4}>
            <FormLabel>Email</FormLabel>
            <Input required type="email" onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl isRequired marginY={8}>
            <FormLabel>Logs File</FormLabel>
            <input type="file" onChange={(e) => setFile(e, setLogsFile)} />
          </FormControl>
          <FormControl isRequired marginBottom={4}>
            <FormLabel>Quarter</FormLabel>
            <Select
              required
              value={quarter}
              onChange={(e) => setQuarter(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Q1 2024">Q1 2024</option>
              <option value="Q2 2024">Q2 2024</option>
              <option value="Q3 2024">Q3 2024</option>
              <option value="Q4 2024">Q4 2024</option>
            </Select>
          </FormControl>
        </form>
        <Button
          colorScheme="blue"
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={![name, email, logsFile, quarter].every((val) => Boolean(val))}
        >
          Submit
        </Button>
      </FormContainer>
    </Page>
  );
}
