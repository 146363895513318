import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Page from "../components/Page/Page";

export default function SuccessPage() {
  const titleElement = document.getElementById("tab-title");
  if (titleElement) {
    titleElement.textContent = `Atomus | Submission Success`;
  }
  const [searchParams] = useSearchParams();
  const form = searchParams.get("form");
  const navigate = useNavigate();
  return (
    <Page>
      <Flex flexDir="column" width="100%" alignItems="center" gap="24px">
        <Box padding={2} borderRadius="md" background="green.100">
          <Flex gap={4} alignItems="center">
            <CheckCircleIcon boxSize={5} color="green.400" />
            <Text fontSize="2xl" fontWeight="bold">
              Thanks! Your submission has been received.
            </Text>
          </Flex>
        </Box>
        {form && (
          <Button
            onClick={() => navigate(`/${form}?${searchParams}`)}
            colorScheme="blue"
            variant="outline"
          >
            Submit another form
          </Button>
        )}
      </Flex>
    </Page>
  );
}
