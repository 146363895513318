import { Box } from "@chakra-ui/react";
import { ChildrenProps } from "../../types";

export default function FormContainer({ children }: ChildrenProps) {
  return (
    <Box borderRadius="md" padding={4} marginY={4} borderWidth="1px" borderColor="blackAlpha.200">
      {children}
    </Box>
  )
}
