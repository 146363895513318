import { useEffect } from 'react';
import HubspotForm from 'react-hubspot-form';
import OldNavbar from '../components/Navbar/Navbar';
import '../components/utilities/helpscout';
import styles from '../styles/PageContentStyles.module.scss';

export default function Support() {

  useEffect(() => {
    return () => {

    };
  }, []);

  return (
    <>
      <OldNavbar />
      <section className={styles.base}>
        <section className={styles.centeredWrapper}>
          <HubspotForm
            region="na1"
            portalId="21111319"
            formId="fcd934d3-1440-48fb-ab2a-37361052929f"
            loading={<div>Loading...</div>}
          />
        </section>
      </section>
    </>

  );
}
