import { Box, Button, Flex, FormControl, FormLabel, Input, Select, Spacer, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendSubContractorFormSubmission } from '../api/api.client';
import { FormCompanyInfo, SubcontractorFormFields } from '../api/api.types';
import FormContainer from '../components/FormContainer/FormContainer';
import Page from '../components/Page/Page';
import { toastFormError } from '../services/formerror.service';

interface FormProps {
  companyInfo: FormCompanyInfo;
}

export default function SCTemplate({ companyInfo }: FormProps) {
  const companyName = companyInfo?.name;
  const companyLogo = (companyInfo.links.length > 0) ? companyInfo.links[0].logo : null;

  const titleElement = document.getElementById('tab-title');
  if (titleElement) {
    titleElement.textContent = `${companyName} | Subcontractor Form`;
  }

  const [scCompanyName, setScCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [infoSystemsCompliant, setInfoSystemsCompliant] = useState<boolean>();
  const [allControlsImplemented, setAllControlsImplemented] = useState<boolean>();
  const [basic31Controls, setBasic31Controls] = useState<boolean>();
  const [atLeast100Controls, setAtLeast100Controls] = useState<boolean>();
  const [poam, setPoam] = useState<boolean>();
  const [poamClosureDate, setPoamClosureDate] = useState("");
  const [pendingControls, setPendingControls] = useState("");
  const [selfSprs, setSelfSprs] = useState<boolean>();
  const [sprsDate, setSprsDate] = useState("");
  const [nistDibcac, setNistDibcac] = useState("");
  const [dibcacDate, setDibcacDate] = useState("");
  const [cotsOnly, setCotsOnly] = useState<boolean>();
  const [securityPlanNameAndVersion, setSecurityPlanNameAndVersion] = useState("");
  const [cageCode, setCageCode] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>();
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const submission: SubcontractorFormFields = {
        companyId: companyInfo.id,
        scCompanyName,
        contactName,
        contactEmail,
        infoSystemsCompliant: infoSystemsCompliant ? infoSystemsCompliant : false,
        allControlsImplemented: allControlsImplemented ? allControlsImplemented : false,
        basic31Controls,
        atLeast100Controls,
        poam: poam ? poam : false,
        poamClosureDate,
        pendingControls,
        selfSprs: selfSprs ? selfSprs : false,
        sprsDate,
        nistDibcac,
        dibcacDate,
        cotsOnly: cotsOnly ? cotsOnly : false,
        securityPlanNameAndVersion,
        cageCode,
      };
      await sendSubContractorFormSubmission(submission);
      navigate("/submit-success");
    } catch (error) {
      toastFormError(toast, error);
    }
    setIsLoading(false);
  };

  return (
    <Page>
      <Flex direction='column'>
        {companyLogo && (
          <div style={{ width: '200px', marginRight: '2px' }}>
            <Box as="img" src={companyLogo} alt="Company Logo" width="100%" height="auto" />
          </div>
        )}
        <Spacer mb={3} />

        <Text fontSize="2xl" fontWeight="bold">{companyName} Cybersecurity Questionaire</Text>
      </Flex>
      <FormContainer>
        <form>
          <FormControl marginBottom={8}>
            <FormLabel>Company Name</FormLabel>
            <Input required value={scCompanyName} onChange={(e) => setScCompanyName(e.target.value)} />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Are your company information systems compliant with the requirements of DFARS 252.204-7012 “Safeguarding Covered Defense Information and Cyber Incident Reporting”?</FormLabel>
            <Select
              required
              value={infoSystemsCompliant === undefined ? "" : infoSystemsCompliant ? "true" : "false"}
              onChange={(e) => setInfoSystemsCompliant(e.target.value === 'true')}
            >
              <option value="">Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Has your company implemented all 110 controls of National Institute of Standards and Technology (NIST) Special Publication (SP) 800-171 on your relevant information systems?</FormLabel>
            <Select required value={allControlsImplemented === undefined ? "" : allControlsImplemented ? 'true' : 'false'} onChange={(e) => setAllControlsImplemented(e.target.value === 'true')}>
              <option value="">Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
          {allControlsImplemented === false && (
            <Box>
              <Text fontSize={"lg"} as='i'>If you answered "No" above:</Text>
              <Spacer></Spacer>
              <FormControl marginBottom={8}>
                <FormLabel>a) Has your company implemented all 31 Basic Security Requirement controls of the NIST SP 800- 171? </FormLabel>
                <Select required value={basic31Controls === undefined ? "" : basic31Controls ? 'true' : 'false'} onChange={(e) => setBasic31Controls(e.target.value === 'true')}>
                  <option value="">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Select>
              </FormControl>

              <FormControl marginBottom={8}>
                <FormLabel>b) Has your company implemented 100 or more controls of the NIST SP 800-171?</FormLabel>
                <Select required value={atLeast100Controls === undefined ? "" : atLeast100Controls ? 'true' : 'false'} onChange={(e) => setAtLeast100Controls(e.target.value === 'true')}>
                  <option value="">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Select>
              </FormControl>
            </Box>
          )}
          <FormControl marginBottom={8}>
            <FormLabel>Is your company operating under a Plan of Action and Milestones (POAM)?</FormLabel>
            <Select required value={poam === undefined ? "" : poam ? 'true' : 'false'} onChange={(e) => setPoam(e.target.value === 'true')}>
              <option value="">Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>

          {poam && (
            <FormControl marginBottom={8}>
              <FormLabel>What is the POAM closure date?</FormLabel>
              <Input
                type="date"
                required
                value={poamClosureDate}
                onChange={(e) => setPoamClosureDate(e.target.value)}
              />
            </FormControl>
          )}

          <FormControl marginBottom={8}>
            <FormLabel>What controls are pending full implementation (e.g. 3.1.1)? </FormLabel>
            <Input
              required
              value={pendingControls}
              onChange={(e) => setPendingControls(e.target.value)}
            />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Has your company conducted a basic self-assessment of the NIST SP 800-171 controls, using the DoD Assessment Methodology, and submitted resulting scores to the DoD Website “Supplier Performance Rating System (SPRS)?</FormLabel>
            <Select required value={selfSprs === undefined ? "" : selfSprs ? 'true' : 'false'} onChange={(e) => setSelfSprs(e.target.value === 'true')}>
              <option value="">Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>

          {selfSprs && (
            <FormControl marginBottom={8}>
              <FormLabel>What is the date submitted to SPRS?</FormLabel>
              <Input
                type="date"
                required
                value={sprsDate}
                onChange={(e) => setSprsDate(e.target.value)}
              />
            </FormControl>
          )}

          <FormControl marginBottom={8}>
            <FormLabel>Has your company obtained a NIST SP 800-171 Medium or High Assessment from the Defense Industrial Base Cybersecurity Assessment Center (DIB CAC) (See DFARS 252.204-7019 and DFARS 252.204- 7020)? </FormLabel>
            <Select required value={nistDibcac} onChange={(e) => setNistDibcac(e.target.value)}>
              <option value="">Select </option>
              <option value="yes (high level)">Yes, High Level </option>
              <option value="yes (medium level)">Yes, Medium Level</option>
              <option value="no">No</option>
            </Select>
          </FormControl>

          {(nistDibcac !== "no") && (nistDibcac !== "") && (
            <FormControl marginBottom={8}>
              <FormLabel>Date of DIBCAC Assessment</FormLabel>
              <Input
                type="date"
                required
                value={dibcacDate}
                onChange={(e) => setDibcacDate(e.target.value)}
              />
            </FormControl>
          )}
          <FormControl marginBottom={8}>
            <FormLabel>Will your company only be providing Commercially available Off-The-Shelf (COTS) items as defined in FAR 2.101? </FormLabel>
            <Select required value={cotsOnly === undefined ? "" : cotsOnly ? 'true' : 'false'} onChange={(e) => setCotsOnly(e.target.value === 'true')}>
              <option value="">Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>What is the name and version number of your system security plan? </FormLabel>
            <Input
              required
              value={securityPlanNameAndVersion}
              onChange={(e) => setSecurityPlanNameAndVersion(e.target.value)}
            />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>CAGE Code</FormLabel>
            <Input
              required
              value={cageCode}
              onChange={(e) => setCageCode(e.target.value)}
            />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Your name</FormLabel>
            <Input required value={contactName} onChange={(e) => setContactName(e.target.value)} />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Email of company POC for cybersecurity</FormLabel>
            <Input required value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
          </FormControl>

        </form>
        <Button
          colorScheme="blue"
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={![
            scCompanyName,
            // NOTE - can add more fields here if we want any additional requirements
          ].every((val) => Boolean(val))}
        >
          Submit
        </Button>
      </FormContainer>
    </Page>
  );
}
