import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendKickOffFormSubmission } from "../api/api.client";
import { KickOffFormFields, O365CloudType } from "../api/api.types";
import FormContainer from "../components/FormContainer/FormContainer";
import KickOffConfirmModal from "../components/Modals/kickoff-submit";
import Page from "../components/Page/Page";
import { toastFormError } from "../services/formerror.service";

export default function KickOffForm() {
  const titleElement = document.getElementById("tab-title");
  if (titleElement) {
    titleElement.textContent = `Atomus | Kick-Off`;
  }
  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminUsername, setAdminUsername] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [numUsers, setNumUsers] = useState("");
  const [additionalDevices, setAdditionalDevices] = useState("0");
  const [countOfServers, setCountOfServers] = useState("0");
  const [cloudInstance, setCloudInstance] = useState(O365CloudType.gccHigh);
  const [needLicenses, setNeedLicenses] = useState(false);
  const [call1StartTime, setCall1StartTime] = useState("");
  const [onboardingDeadline, setOnboardingDeadline] = useState("");
  const [migration, setMigration] = useState(false);
  const [call2StartTime, setCall2StartTime] = useState(""); // if migration
  const [migrationRemarks, setMigrationRemarks] = useState(""); // if migration
  const [customDomain, setCustomDomain] = useState("");
  const [scoreRequired, setScoreRequired] = useState(false);
  const [call3StartTime, setCall3StartTime] = useState(""); // if score required
  const [shortForm, setShortForm] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const submission: KickOffFormFields = {
        adminFirstName,
        adminLastName,
        adminEmail,
        adminUsername,
        adminPhone,
        companyName,
        companyAddress,
        companyWebsite,
        numUsers,
        additionalDevices,
        countOfServers,
        cloudInstance,
        needLicenses,
        call1StartTime,
        onboardingDeadline,
        migration,
        call2StartTime,
        migrationRemarks,
        customDomain,
        scoreRequired,
        call3StartTime,
        shortForm,
      };
      await sendKickOffFormSubmission(submission);
      navigate("/submit-success");
    } catch (error) {
      toastFormError(toast, error);
    }
    setIsLoading(false);
  };

  const onSubmit = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    // clear fields if unchecked
    if (!migration) {
      setCall2StartTime("");
      setMigrationRemarks("");
    }
    if (!scoreRequired) {
      setCall3StartTime("");
    }
  }, [migration, scoreRequired]);

  return (
    <Page>
      <Text fontSize="2xl" fontWeight="bold">
        Kick-Off
      </Text>
      <br></br>
      <Text>
        This form will help us kick-off your security and compliance journey
        with Atomus!
      </Text>
      <FormContainer>
        <form>
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Name</FormLabel>
            <Flex>
              <Input
                flex="1"
                marginRight={2}
                required
                value={adminFirstName}
                onChange={(e) => setAdminFirstName(e.target.value)}
                placeholder="First"
              />
              <Input
                flex="1"
                marginLeft={2}
                required
                value={adminLastName}
                onChange={(e) => setAdminLastName(e.target.value)}
                placeholder="Last"
              />
            </Flex>
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Email</FormLabel>
            <Input
              required
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Username</FormLabel>
            <FormHelperText marginBottom={1.5}>
              {"<username>@domain.com"}
            </FormHelperText>
            <Input
              required
              value={adminUsername}
              onChange={(e) => setAdminUsername(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Phone</FormLabel>
            <Input
              required
              value={adminPhone}
              onChange={(e) => setAdminPhone(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Company Name</FormLabel>
            <Input
              required
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Short Form</FormLabel>
            <Input
              required
              value={shortForm}
              maxLength={20}
              onChange={(e) => setShortForm(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Company Website</FormLabel>
            <Input
              value={companyWebsite}
              onChange={(e) => setCompanyWebsite(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Company Address</FormLabel>
            <Textarea
              required
              value={companyAddress}
              size="md"
              resize="vertical"
              rows={3}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Number of Users</FormLabel>
            <Input
              required
              value={numUsers}
              onChange={(e) => setNumUsers(e.target.value)}
            />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Additional Devices?</FormLabel>
            <Input
              required
              value={additionalDevices}
              onChange={(e) => setAdditionalDevices(e.target.value)}
            />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Count of Servers</FormLabel>
            <Input
              required
              value={countOfServers}
              onChange={(e) => setCountOfServers(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Cloud Instance</FormLabel>
            <Select
              required
              value={cloudInstance === undefined ? "" : cloudInstance}
              onChange={(e) =>
                setCloudInstance(e.target.value as O365CloudType)
              }
            >
              <option value={O365CloudType.gccHigh}>GCC High</option>
              <option value={O365CloudType.gcc}>GCC</option>
            </Select>
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Licenses from Atomus?</FormLabel>
            <Checkbox
              isChecked={needLicenses}
              onChange={(e) => setNeedLicenses(e.target.checked)}
            >
              Check if applicable
            </Checkbox>
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Custom Domain</FormLabel>
            <Input
              required
              value={customDomain}
              onChange={(e) => setCustomDomain(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>
              [Call 1] Onboarding Status Discussion Start Time
            </FormLabel>
            <Input
              required
              type="datetime-local"
              value={call1StartTime}
              onChange={(e) => setCall1StartTime(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired marginBottom={8}>
            <FormLabel>Onboarding Deadline</FormLabel>
            <Input
              required
              type="date"
              value={onboardingDeadline}
              onChange={(e) => setOnboardingDeadline(e.target.value)}
            />
          </FormControl>

          <FormControl marginBottom={8}>
            <FormLabel>Migration?</FormLabel>
            <Checkbox
              isChecked={migration}
              onChange={(e) => setMigration(e.target.checked)}
            >
              Check if applicable
            </Checkbox>
          </FormControl>

          {migration && (
            <>
              <FormControl isRequired marginBottom={8}>
                <FormLabel>[Call 2] Migration Start Time</FormLabel>
                <Input
                  required
                  type="datetime-local"
                  value={call2StartTime}
                  onChange={(e) => setCall2StartTime(e.target.value)}
                />
              </FormControl>

              <FormControl marginBottom={8}>
                <FormLabel>
                  Remarks? (Migration from Google/O365 Commercial?)
                </FormLabel>
                <Input
                  required
                  value={migrationRemarks}
                  onChange={(e) => setMigrationRemarks(e.target.value)}
                />
              </FormControl>
            </>
          )}

          <FormControl marginBottom={8}>
            <FormLabel>Documentation or SPRS Score Required</FormLabel>
            <Checkbox
              isChecked={scoreRequired}
              onChange={(e) => setScoreRequired(e.target.checked)}
            >
              Check if applicable
            </Checkbox>
          </FormControl>

          {scoreRequired && (
            <FormControl isRequired marginBottom={8}>
              <FormLabel>[Call 3] Start Time</FormLabel>
              <Input
                required
                type="datetime-local"
                value={call3StartTime}
                onChange={(e) => setCall3StartTime(e.target.value)}
              />
            </FormControl>
          )}
        </form>
        <Button
          colorScheme="blue"
          onClick={onSubmit}
          isLoading={isLoading}
          isDisabled={
            ![
              adminFirstName,
              adminLastName,
              adminEmail,
              adminPhone,
              companyName,
              companyAddress,
              onboardingDeadline,
              shortForm,
              cloudInstance,
            ].every((val) => Boolean(val))
          }
        >
          Submit
        </Button>
      </FormContainer>
      <KickOffConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={handleSubmit}
      />
    </Page>
  );
}
