/**
 * @description validates file extensions and size
 * @param file the file to validate
 */
export function checkFile(file: File) {
  const ALLOWED_EXTS = [".zip", ".pdf", ".png", ".jpg", ".jpeg", ".heic"];
  const fileExt = file.name.match(/\.[^.]*$/)?.[0];
  if (!fileExt) {
    throw new Error("Unable to determine file type. Please check the file name and extension.");
  }
  let fileExtOK = false;
  for (const ext of ALLOWED_EXTS) {
    if (fileExt?.toLowerCase().includes(ext)) {
      fileExtOK = true;
    }
  }
  if (!fileExtOK) {
    throw new Error("File type not permitted. Uploads can be images and zip folders only.");
  }
  const fileSize = file.size / (1024 ** 2);
  if (fileSize > 5) {
    throw new Error("File exceeds size limit.");
  }
}
