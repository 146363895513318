import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export default function usePageTracking() {
  const location = useLocation();
  const TRACKING_ID = 'UA-208927109-1';

  useEffect(() => {
    // If testing Google Analytics, use this
    // ReactGA.initialize(TRACKING_ID, { debug: true, testMode: true });
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}
