import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendVisitorFormSubmission } from "../api/api.client";
import { VisitorFormSubmission } from "../api/api.types";
import FormContainer from "../components/FormContainer/FormContainer";
import Page from "../components/Page/Page";
import { toastFormError } from "../services/formerror.service";

export default function VisitorForm() {
  const titleElement = document.getElementById("tab-title");
  if (titleElement) {
    titleElement.textContent = `Atomus | Visitor Sign-in`;
  }

  const [searchParams] = useSearchParams();
  const [visitorName, setVisitorName] = useState("");
  const [visitorPhone, setVisitorPhone] = useState("");
  const [visitorEmail, setVisitorEmail] = useState("");
  const [isUsCitizen, setIsUsCitizen] = useState("");
  const [defaultLocation, setDefaultLocation] = useState(
    searchParams.get("defaultLocation") !== "false"
  );
  const [location, setLocation] = useState<string | null>(
    searchParams.get("location") !== "null"
      ? searchParams.get("location")
      : null
  );
  const [defaultTime, setDefaultTime] = useState(
    searchParams.get("defaultTime") !== "false"
  );
  const [timeOfVisit, setTimeOfVisit] = useState(
    searchParams.get("timeOfVisit") !== "null"
      ? searchParams.get("timeOfVisit") ?? ""
      : ""
  );
  const [purposeOfVisit, setPurposeOfVisit] = useState(
    searchParams.get("purposeOfVisit") ?? ""
  );
  const [employeeName, setEmployeeName] = useState(
    searchParams.get("employeeName") ?? ""
  );
  const [employeeEmail, setEmployeeEmail] = useState(
    searchParams.get("employeeEmail") ?? ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const submittedLocation = defaultLocation ? null : location;
      const submittedTime = defaultTime ? null : timeOfVisit;
      const submission: VisitorFormSubmission = {
        name: visitorName,
        phone: visitorPhone,
        email: visitorEmail,
        isUsCitizen: isUsCitizen === "" ? null : isUsCitizen === "true",
        location: submittedLocation,
        timeOfVisit: submittedTime,
        purposeOfVisit,
        employeeName,
        employeeEmail,
      };
      await sendVisitorFormSubmission(submission);
      const redirectUrl = "/submit-success";
      const params = new URLSearchParams({
        form: "visitors",
        location: submittedLocation ?? "null",
        timeOfVisit: submittedTime ?? "null",
        purposeOfVisit,
        employeeName,
        employeeEmail,
        defaultLocation: defaultLocation ? "true" : "false",
        defaultTime: defaultTime ? "true" : "false",
      });
      navigate(`${redirectUrl}?${params}`);
    } catch (error) {
      toastFormError(toast, error);
    }
    setIsLoading(false);
  };

  return (
    <Page>
      <Text fontSize="2xl" fontWeight="bold">
        Visitor Sign-in
      </Text>
      <Text>
        This is a visitor sign-in form allowing our organization to meet
        compliance and security requirements.
      </Text>
      <FormContainer>
        <form>
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Visitor name</FormLabel>
            <Input
              required
              value={visitorName}
              onChange={(e) => setVisitorName(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Visitor Phone Number</FormLabel>
            <Input
              required
              value={visitorPhone}
              onChange={(e) => setVisitorPhone(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Visitor Email Address</FormLabel>
            <Input
              required
              value={visitorEmail}
              onChange={(e) => setVisitorEmail(e.target.value)}
            />
          </FormControl>
          <FormControl marginBottom={8}>
            <FormLabel>Is the visitor a U.S. Citizen / U.S. Person?</FormLabel>
            <Select
              required
              value={isUsCitizen}
              onChange={(e) => setIsUsCitizen(e.target.value)}
            >
              <option value="">Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
          <FormLabel>Location:</FormLabel>
          <Flex flexDirection="row" marginBottom={4}>
            <Checkbox
              marginLeft={2}
              isChecked={defaultLocation}
              onChange={(e) => setDefaultLocation(e.target.checked)}
            />
            <FormLabel margin={3}>Use default company address</FormLabel>
          </Flex>
          {!defaultLocation && (
            <FormControl isRequired marginBottom={8}>
              <Text marginBottom={1}>
                Address of the location you are visiting
              </Text>
              <Input
                required
                value={location ?? ""}
                onChange={(e) => setLocation(e.target.value)}
              />
            </FormControl>
          )}
          <FormLabel>Time of Visit</FormLabel>
          <Flex flexDirection="row" marginBottom={4}>
            <Checkbox
              marginLeft={2}
              isChecked={defaultTime}
              onChange={(e) => setDefaultTime(e.target.checked)}
            />
            <FormLabel margin={3}>Now</FormLabel>
          </Flex>
          {!defaultTime && (
            <FormControl marginBottom={8}>
              <Input
                required
                type="datetime-local"
                value={timeOfVisit}
                onChange={(e) => setTimeOfVisit(e.target.value)}
              />
            </FormControl>
          )}
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Purpose of Visit</FormLabel>
            <Input
              required
              value={purposeOfVisit}
              onChange={(e) => setPurposeOfVisit(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Employee Name</FormLabel>
            <Input
              required
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired marginBottom={8}>
            <FormLabel>Employee Email</FormLabel>
            <Input
              required
              value={employeeEmail}
              onChange={(e) => setEmployeeEmail(e.target.value)}
            />
          </FormControl>
        </form>
        <Text as="i" fontSize="sm">
          By submitting this form,
          <Text display="inline" as="b">
            {" "}
            you agree to not take any photos or videos within the organization
            facility without explicit permission.
          </Text>
        </Text>
        <Spacer />
        <Button
          marginTop={5}
          colorScheme="blue"
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={
            ![
              visitorName,
              visitorPhone,
              visitorEmail,
              employeeName,
              employeeEmail,
            ].every((val) => Boolean(val))
          }
        >
          Submit
        </Button>
      </FormContainer>
    </Page>
  );
}
